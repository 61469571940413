<template>
  <v-container fluid>
    <v-card-title>
      배너 등록
      <v-spacer />
    </v-card-title>
    <v-card>
      <Banner :type="type" />
    </v-card>
  </v-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import Banner from '@/components/Banner.vue'

export default defineComponent({
  props: { type: String },

  components: { Banner },
})
</script>
