<template>
  <v-container fluid>
    <v-list>
      <v-row no-gutters class="mb-3">
        <v-card-actions>
          <v-btn text color="grey">노출 여부</v-btn>
          <v-spacer />
          <v-btn small depressed :text="status !== 'show'" :color="status !== 'show' ? null : '#ffd600'" :light="status === 'show'" @click="status = 'show'">
            노출
          </v-btn>
          <v-btn small depressed :text="status !== 'keep'" :color="status !== 'keep' ? null : '#ffd600'" :light="status === 'keep'" @click="status = 'keep'">
            비노출
          </v-btn>
          <v-btn
            small
            depressed
            :text="status !== 'pending'"
            :color="status !== 'pending' ? null : '#ffd600'"
            :light="status === 'pending'"
            @click="status = 'pending'"
          >
            노출예약
          </v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn text color="grey">예약 시간</v-btn>
          <v-menu v-model="dateMenu" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                :disabled="status !== 'pending'"
                v-bind="attrs"
                outlined
                hide-details
                readonly
                dense
                placeholder="시작일"
                class="mx-3"
                v-on="on"
              />
            </template>
            <v-date-picker v-model="date" @input="dateMenu = false" />
          </v-menu>
          <v-menu v-model="timeMenu" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="time"
                :disabled="status !== 'pending'"
                v-bind="attrs"
                outlined
                hide-details
                readonly
                dense
                placeholder="시간"
                v-on="date ? on : null"
              />
            </template>
            <v-time-picker v-model="time" ampm-in-title @click:minute="timeMenu = false" />
          </v-menu>
        </v-card-actions>
      </v-row>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>서브 문구</v-list-item-title>
          <v-list-item-subtitle>
            메인문구 위 작게 나오는 문구
          </v-list-item-subtitle>
          <v-text-field v-model="banner.title" outlined dense hide-details />
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>메인 문구</v-list-item-title>
          <v-list-item-subtitle>
            서브문구 아래 크게 나오는 문구
          </v-list-item-subtitle>
          <v-text-field v-model="banner.copyright" outlined dense hide-details />
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>링크</v-list-item-title>
          <v-list-item-subtitle>
            배너를 눌렀을 때 연결될 url을 넣어주세요.
          </v-list-item-subtitle>
          <v-text-field v-model="banner.destination" outlined dense hide-details />
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>배너 이미지</v-list-item-title>
          <v-list-item-subtitle>
            배너에 노출될 이미지를 등록해주세요.
          </v-list-item-subtitle>
          <v-row no-gutters>
            <v-card rounded flat outlined @click="$refs.input.$refs.input.click()">
              <v-img v-if="imageUrl" :src="imageUrl" width="300px">
                <v-layout justify-center align-center fill-height>
                  <v-icon large>
                    mdi-image-edit
                  </v-icon>
                </v-layout>
              </v-img>

              <v-sheet v-else width="300px" height="200px" color="#ffd600">
                <v-layout justify-center align-center fill-height>
                  <v-icon dark large>
                    mdi-image-edit
                  </v-icon>
                </v-layout>
              </v-sheet>
            </v-card>

            <v-file-input ref="input" v-model="image" class="d-none" hide-input />
          </v-row>
        </v-list-item-content>
      </v-list-item>

      <div v-if="type === 'reply'">
        <v-list-item>
          <v-list-item-title>
            유저 조건 선택
            <span class="ml-4" style="font-size: 13px">
              배너를 노출할 유저조건을 선택해주세요.
            </span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          전체
          <v-switch v-model="all" inset color="deep-purple accent-1" class="ml-10 mt-0" dense hide-details @change="changeAll" />
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              조건 선택
              <div style="margin: -16px 80px 0px; font-size: 13px">
                <div>
                  특정 조건을 선택하여 배너를 노출하고 싶을 때 사용합니다.
                </div>
                <div>
                  추가하고 싶은 조건을 선택하여 조건추가 버튼을 누르면 조건을 추가할 수 있습니다.
                </div>
                <div>복수 선택 가능하며, 각 조건은 OR 조건이 적용됩니다.</div>
              </div>
            </v-list-item-title>
            <v-row class="mt-4">
              <v-col cols="4">
                <v-select
                  v-for="(e, i) in labelList"
                  :key="i"
                  v-model="req.select[e.model]"
                  :prepend-icon="`mdi-numeric-${i + 1}-box`"
                  :disabled="e.disabled ? !req.select[e.disabled] : null"
                  :items="select[e.model]"
                  :append-icon="null"
                  color="#ffd600"
                  clearable
                  outlined
                  dense
                  :menu-props="{
                    maxHeight: 1024,
                  }"
                />
                <v-btn block color="#ffd600" light :disabled="all" @click="pushStampList">
                  조건추가
                </v-btn>
              </v-col>
              <v-col>
                <div v-for="(stamp, index) in banner.Stamp" :key="index">
                  {{
                    `${index + 1}. ${stamp.firstDepth} ${stamp.secondDepth ? '- ' + stamp.secondDepth : ''} ${stamp.thirdDepth ? '- ' + stamp.thirdDepth : ''}`
                  }}
                  <v-btn light class="ml-3 mb-1" rounded small color="#ffd600" @click="banner.Stamp.splice(index, 1)" v-text="'삭제'" />
                </div>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" @click="updateBanner">저장하기</v-btn>
      <v-btn color="error" @click="exit">취소</v-btn>
    </v-card-actions>
  </v-container>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, toRefs, watch, computed } from '@vue/composition-api'

export default defineComponent({
  props: { type: String },

  setup(props, { root }) {
    const state = reactive({
      status: 'keep',
      date: null,
      time: null,
      dateMenu: false,
      timeMenu: false,
      bannerList: [],
      all: false,
      banner: {
        title: null,
        copyright: null,
        destination: null,
        bannerType: null,
        banner: null,
        Stamp: [],
        publishedAt: null,
      },
      image: null,
      labelList: [{ model: 'firstDepth' }, { model: 'secondDepth', disabled: 'firstDepth' }, { model: 'thirdDepth', disabled: 'secondDepth' }],
      select: {
        firstDepth: [],
        secondDepth: [],
        thirdDepth: [],
      },
      req: {
        id: null,
        select: {
          firstDepth: null,
          secondDepth: null,
          thirdDepth: null,
        },
      },
    })

    const imageUrl = computed(() => {
      if (state.image) return URL.createObjectURL(state.image)
      return state.banner.banner
    })

    const changeAll = async () => {
      state.banner.Stamp = []
    }

    const getOneBanner = async () => {
      const banner = await root.$store.dispatch('banner/getOneBanner', {
        id: Number(root.$route.params.id),
      })
      state.banner = { ...banner }

      if (state.banner.publishedAt) {
        const date = new Date(state.banner.publishedAt)
        const Y = String(date.getFullYear())
        const M = String(date.getMonth() + 1).padStart(2, '0')
        const D = String(date.getDate()).padStart(2, '0')
        const hh = String(date.getHours()).padStart(2, '0')
        const mm = String(date.getMinutes()).padStart(2, '0')
        state.date = Y + '-' + M + '-' + D
        state.time = hh + ':' + mm

        if (new Date(state.banner.publishedAt) > new Date()) {
          state.status = 'pending'
        } else {
          state.status = 'show'
        }
      }
    }

    const createBanner = async () => {
      try {
        if (!confirm('배너를 저장합니다.')) return

        let date = null
        if (state.date && state.time) date = new Date(`${state.date}T${state.time}`)
        else if (state.date) date = new Date(state.date + 'T00:00')

        await root.$store.dispatch('banner/createBanner', {
          bannerType: props.type,
          title: state.banner.title ?? undefined,
          destination: state.banner.destination ?? '',
          copyright: state.banner.copyright ?? '',
          banner: state.image ?? undefined,
          publishedAt: state.status === 'keep' ? null : date ? date : new Date(),
        })

        exit()
      } catch (err) {
        console.error(err)
        alert(err)
      }
    }

    const updateBanner = async () => {
      if (!root.$route.params.id) return createBanner()

      if (!confirm('배너를 수정합니다.')) return

      try {
        let date = null
        if (state.date && state.time) date = new Date(state.date + 'T' + state.time)
        else if (state.date) date = new Date(state.date + 'T00:00')

        await root.$store.dispatch('banner/updateBanner', {
          id: Number(root.$route.params.id),
          bannerType: state.banner.bannerType,
          destination: state.banner.destination,
          title: state.banner.title,
          copyright: state.banner.copyright,
          banner: state.image ?? undefined,
          stampId: state.banner.bannerType === 'reply' ? state.banner.Stamp.map((e) => Number(e.id)) : undefined,
          publishedAt: state.status === 'keep' ? null : state.status === 'pending' ? date : new Date(),
        })

        exit()
      } catch (err) {
        alert(err)
      }
    }

    const exit = () => {
      root.$router.push('/banner/' + props.type)
    }

    const selectFirstDepth = async () => {
      const stamp = await root.$store.dispatch('stamp/getNewStamp', {
        firstDepth: state.req.select.firstDepth,
      })

      state.select.secondDepth = []
      stamp.map((e) => {
        if (e.secondDepth !== null) state.select.secondDepth.push(e.secondDepth)
        else state.req.id = e.id
      })
    }

    const selectSecondDepth = async () => {
      const stamp = await root.$store.dispatch('stamp/getNewStamp', {
        firstDepth: state.req.select.firstDepth,
        secondDepth: state.req.select.secondDepth,
      })

      state.select.thirdDepth = []
      stamp.map((e) => {
        if (e.thirdDepth !== null) state.select.thirdDepth.push(e.thirdDepth)
        else state.req.id = e.id
      })
    }

    const selectThirdDepth = async () => {
      const stamp = await root.$store.dispatch('stamp/getNewStamp', {
        firstDepth: state.req.select.firstDepth,
        secondDepth: state.req.select.secondDepth,
      })

      stamp.map((e) => {
        if (e.thirdDepth === state.req.select.thirdDepth) state.req.id = e.id
      })
    }

    const pushStampList = () => {
      if (state.banner.Stamp.filter((e) => e.id === state.req.id)[0]) return

      state.banner.Stamp.push({
        ...state.req.select,
        id: state.req.id,
      })
    }

    onBeforeMount(async () => {
      if (root.$route.params.id) getOneBanner()

      const stamp = await root.$store.dispatch('stamp/getNewStamp', {})
      stamp.map((e) => {
        state.select.firstDepth.push(e.firstDepth)
      })
    })

    watch(
      () => state.req.select.firstDepth,
      () => {
        state.req.select.secondDepth = null
        state.req.select.thirdDepth = null
        if (state.req.select.firstDepth) selectFirstDepth()
      },
    )

    watch(
      () => state.req.select.secondDepth,
      () => {
        state.req.select.thirdDepth = null
        if (state.req.select.secondDepth) selectSecondDepth()
        else if (state.req.select.firstDepth) selectFirstDepth()
      },
    )

    watch(
      () => state.req.select.thirdDepth,
      () => {
        if (state.req.select.thirdDepth) selectThirdDepth()
        else if (state.req.select.secondDepth) selectSecondDepth()
        else if (state.req.select.firstDepth) selectFirstDepth()
      },
    )

    return {
      ...toRefs(state),
      imageUrl,
      changeAll,
      pushStampList,
      updateBanner,
      exit,
    }
  },
})
</script>
